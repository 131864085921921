import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Page404 from '../components/error/Page404';
import Authentication from '../containers/auth/Authentication';
import Claudia from '../containers/games/claudia/Claudia';
import Dora from '../containers/games/dora/Dora';
import Katy from '../containers/games/katy/Katy';
import Selena from '../containers/games/selena/Selena';
import Tania from '../containers/games/tania/Tania';
import RoomList from '../containers/rooms/RoomList';

const Router = () => {
    return(
        <>
            <BrowserRouter>
				<Routes>
                    <Route path='/redirect/:contractcode/:secretcontract/:roomname/:user/:session' element={<Authentication/>}/>
                    <Route path='/redirect/:contractcode/:secretcontract/:roomname' element={<Authentication/>}/>
                    
                    <Route path='/rooms' element={<RoomList/>}/>

					<Route path='/play/tania/:credentials' element={<Tania/>}/>
                    <Route path='/play/selena/:credentials' element={<Selena/>}/>
                    <Route path='/play/katy/:credentials' element={<Katy/>}/>
                    <Route path='/play/cl/:roomid/:credentials' element={<Claudia/>}/>
                    <Route path='/play/dr/:roomid/:credentials' element={<Dora/>}/>

                    <Route path='*' element={<Page404/>}/>
				</Routes>
			</BrowserRouter>
        </>
    );
}

export default Router;