import { useEffect, useState } from "react";

const PlayButton = (props) => {
    const [playButton, setPlayButton] = useState('');
    const [ctrlPlay, setCtrlPlay] = useState(false);
    const [ctrlDisabled, setCtrlDisabled] = useState(false);

    useEffect(() => {
        if(props.isplaying === 'true' && props.socketInfo.STS_MACH !== 'AVL'){
            if(props.socketInfo.STS_MACH === 'CD_PLAY' || props.socketInfo.STS_MACH == 'GRBING' || props.socketInfo.STS_MACH == 'RWDING' || 
            (props.socketInfo.STS_MACH == 'CD_CONT' && props.socketInfo.CD_CONT != 0)){
                setPlayButton('hidden');
                setCtrlPlay(false);
            }
            else if(props.socketInfo.STS_MACH === 'BL_RLING' 
            || props.socketInfo.STS_MACH === 'RWD' 
            || props.socketInfo.STS_MACH === 'CD_BNS' 
            || props.socketInfo.STS_MACH === 'BNS' 
            || props.socketInfo.STS_MACH === 'SWPING'
            || (props.socketInfo.STS_MACH === 'CD_CONT' 
            && props.socketInfo.CD_CONT !== 0)){
                setPlayButton('hidden');
                setCtrlPlay(false);
            }else{
                setPlayButton('block');
                setCtrlPlay(true);
            }
        }else{
            if(props.socketInfo.STS_MACH !== 'AVL' && props.socketInfo.STS_MACH !== 'CONNECTED' && props.socketInfo.ACT !== 'DONE' && props.socketInfo.STS_MACH !== 'STRT'){
                setCtrlPlay(false);
                setCtrlDisabled(true);
            }else{
                setCtrlPlay(true);
                setCtrlDisabled(false);
            }
        }

        if(props.socketInfo.STS_MACH === 'AVL'){
            setPlayButton('block');
            setCtrlPlay(true);
            setCtrlDisabled(false);
        }
    }, [props]);
    return(
        <>
            <div className={playButton}>
                <div className="flex items-center">
                    <div className="flex-auto">
                        <img src={process.env.PUBLIC_URL+'/new-skin/help.png'} alt="help.png" className="sm:w-[40px] md:w-arcadesWidth50px mr-auto cursor-pointer active:translate-y-[4px]" onClick={props.help}/>
                    </div>
                    <div className="flex-auto mt-[-35px] relative text-center">
                        {ctrlPlay ?
                        <div onClick={props.play} className="cursor-pointer active:translate-y-[4px] mt-[30px]">
                            <img src={process.env.PUBLIC_URL+'/new-skin/play.png'} alt="play.png" className="sm:w-[180px] md:w-arcadesWidth150px m-auto"/>
                        </div> : '' }

                        {ctrlDisabled ?
                        <div className="mt-[30px]">
                            <img src={process.env.PUBLIC_URL+'/new-skin/ctrl-disabled.png'} alt="ctrl-disabled.png" className="sm:w-[180px] md:w-arcadesWidth150px m-auto"/>
                        </div> : '' }
                    </div>
                    <div className="flex-auto">
                        <img src={process.env.PUBLIC_URL+'/new-skin/rank.png'} alt="rank.png" className="sm:w-[40px] md:w-arcadesWidth50px ml-auto active:translate-y-[4px] cursor-pointer" onClick={props.rank}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlayButton;