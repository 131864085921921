import { WhatsappShareButton, WhatsappIcon } from "react-share";

const Win = (props) => {
    return(
        <>
            {props.modal ? ( <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t mt-[-130px]">
                            <img src={'/images/modal/win.png'} alt="win.png" className="w-[100%] m-auto bg-no-repeat bg-transparent"/>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto mt-[43px]">
                            <img src={'/images/modal/confetti.png'} alt="confetti.png" className="w-[75%] m-auto mt-[-4rem] mb-[79px] animate-arcadesStart"/>
                            <img src={'/images/modal/boneka.png'} alt="boneka.png" className="w-[50%] m-auto mt-[-228px]"/>

                            <p className="bg-[#ff9c01] w-[38%] m-auto text-center p-arcadesPadding2 rounded-[15px] font-bold">{props.reward}</p>
                            <span className="block text-black text-[10px] text-center pt-[10px]">Hadiah secara otomatis di conversi ke diamond.</span>
                            <span className="block text-black text-[10px] text-center">Hubungi CS kami, jika Anda ingin mendapatkan hadiah ini.</span>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            { props.continue > 0 ? <>
                            {/* <button>
                                <img src={process.env.PUBLIC_URL+'/images/modal/share.png'} alt="share.png" className="w-[50px] mr-2.5"/>
                            </button> */}
                            <WhatsappShareButton url={props.urlwhatsapp} title={props.title}>
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            
                            <button className="bg-arcadesGradients font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] mr-2.5 active:translate-y-[4px]" onClick={props.playagain}>
                                Main Lagi { props.continue > 0 ? `( `+props.continue+` )` : '' }
                            </button>
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button></> : ''  }
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div> </> ): '' }
        </>
    );
}

export default Win;