const Lose = (props) => {
    return(
        <>
            {props.modal ? ( <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t mt-[-77px]">
                            <img src={'/images/modal/lose-1.png'} alt="lose-1.png" className="w-[75%] m-auto bg-no-repeat bg-transparent"/>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <img src={process.env.PUBLIC_URL+'/images/modal/treasure-chest.png'} alt="treasure-chest.png" className="w-[50%] m-auto mt-[-27px]"/>
                            <h5 className="text-black text-center text-[30px] pt-[20px]">Jangan Menyerah!</h5>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            { props.continue > 0 ? <>
                            <button className="bg-arcadesGradients font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] mr-2.5 active:translate-y-[4px]" onClick={props.playagain}>
                                Main Lagi { props.continue > 0 ? `( `+props.continue+` )` : '' }
                            </button> 
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button></>: '' }
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div></> ): '' }
        </>
    );
}

export default Lose;