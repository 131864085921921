import LeaderBoard from "./leaderboard/LeaderBoard";

const List = () => {
    return(
        <>
            <div className="bg-white w-full rounded-[20px] shadow-arcadesShadow border border-slate-300 relative mb-5">
                <div className="w-[45%] absolute">
                    <img src={process.env.PUBLIC_URL+'/images/support/win-this.png'} alt="win-this.png" className="w-[45%] rounded-tl-[20px]"/>
                </div>
                <div className="bg-arcadesGradients1 w-full h-[135px] rounded-t-[20px]">
                    <div className="flex flex-row">
                        <div style={{ backgroundImage: "url('/images/reward/reward.png')"}} className="flex-initial w-[50%] bg-arcadesControl h-[115px] m-arcadesMargin"></div>
                        <div className="flex-initial w-[50%]">
                            <div className="pl-[20px] pr-[10px] pt-[10px]">
                                <h6 className="text-black font-medium">Leader Board</h6>

                                <div className="overflow-auto max-h-[85px]">
                                    <LeaderBoard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="flex-initial w-[50%]">
                        <div className="pt-[15px] pl-[20px]">
                            <h5 className="text-[#ff7b3d] font-bold text-[1.25em]">CL02</h5>
                            <p className="text-black leading-[1.2] text-[80%] pt-2 pb-5">Ducklings are the cutest of them all, rescue them out and have them delivered to your doorstep in no time!”</p>
                        </div>
                    </div>
                    <div className="flex-initial w-[50%] self-center text-center">
                        <div className="flex flex-row justify-center">
                            <div className="mr-2">
                                <img src={process.env.PUBLIC_URL+'/images/support/coin.png'} alt="coin.png" className="w-[25px] m-auto"/>
                            </div>
                            <span className="text-black font-bold">5</span>
                        </div>
                        <div>
                            <button>
                                <img src={process.env.PUBLIC_URL+'/images/support/button-play.png'} alt="button-play.png" className="w-[45%] m-auto mt-2"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default List;