const Header = (props) => {
    const backHandle = () => {
        window.location.href = props.roomconfig.tableroomconfigurlback
    }
    return(
        <>
            <div style={{ backgroundImage: props.urlImage }} className="z-[1] w-full absolute h-arcadesHeader bg-no-repeat bg-cover bg-bottom">
                <div className="mx-4 mt-2">
                    <div className="flex pt-2 items-center gap-4">
                        <div>
                            <img src={process.env.PUBLIC_URL + '/new-skin/back.png'} alt="back.png" className="w-arcadesWidth50px cursor-pointer active:translate-y-[4px]" onClick={backHandle}/>
                        </div> 
                        <div className="flex-initial w-44 ml-1.5">
                            <div style={{ backgroundImage: "url('/new-skin/balance.png')"}} className="relative top-[-2px] sm:h-[2rem] md:h-[36px] bg-no-repeat bg-center bg-arcadesControl">
                                <img src={process.env.PUBLIC_URL+'/images/support/diamond.png'} alt="diamond.png" className="absolute w-6 sm:pt-[4px] md:pt-1"/>
                                <span className="ml-8 block sm:pt-[4px] md:pt-1 font-bold absolute">{props.play.tableuserreward}</span>
                                <img src={process.env.PUBLIC_URL+'/new-skin/add.png'} alt="add.png" className="absolute sm:w-[21px] md:w-6 sm:pt-[6px] md:pt-1 right-[-10px]"/>
                            </div> 
                        </div> 
                        <div className="flex-initial w-44">
                            <div style={{ backgroundImage: "url('/new-skin/balance.png')"}} className="relative top-[-2px] sm:h-[2rem] md:h-[36px] bg-no-repeat bg-center bg-arcadesControl">
                                <img src={process.env.PUBLIC_URL+'/images/support/coin.png'} alt="coin.png" className="absolute w-6 sm:pt-[4px] md:pt-1"/>
                                <span className="ml-8 block sm:pt-[4px] md:pt-1 font-bold absolute">{props.play.tableuserpoint}</span>
                                <img src={process.env.PUBLIC_URL+'/new-skin/add.png'} alt="add.png" className="absolute sm:w-[21px] md:w-6 sm:pt-[6px] md:pt-1 right-[-10px]"/>
                            </div>
                            {/* <div style={{ backgroundImage: "url('/images/support/balance.png')"}} className="relative top-[-2px] sm:h-[2rem] md:h-12 bg-no-repeat bg-center bg-cover">
                                <img src={process.env.PUBLIC_URL+'/images/support/coin.png'} alt="coin.png" className="absolute w-6 pt-3 ml-3.5"/>
                                <span className="ml-11 block pt-3 font-bold absolute">4,0000,0000</span>
                                <img src={process.env.PUBLIC_URL+'/images/support/add.png'} alt="add.png" className="absolute w-6 pt-3 right-0"/>
                            </div> */}
                        </div> 
                        <div>
                            <button onClick={props.help}>
                                <img src={process.env.PUBLIC_URL + '/new-skin/button-csupport.png'} alt="button-csupport.png" className="w-arcadesWidth50px active:translate-y-[4px]"/>
                            </button>
                        </div>
                        <div>
                            <button onClick={props.roomaudio}>
                                {props.audioroom ? 
                                <img src={process.env.PUBLIC_URL + '/new-skin/audio-on.png'} alt="audio-on.png" className="w-arcadesWidth50px active:translate-y-[4px]"/>
                                : <img src={process.env.PUBLIC_URL + '/new-skin/audio-mute.png'} alt="audio-mute.png" className="w-arcadesWidth50px active:translate-y-[4px]"/> }
                            </button>
                        </div> 
                    </div> 
                </div> 
            </div>
        </>
    );
}

export default Header;