const Rank = (props) => {
    return(
        <>
            {props.modal ? (<>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t mt-[-60px] absolute">
                            <img src={'/images/modal/rewards.png'} alt="rewards.png" className="w-[75%] m-auto bg-no-repeat bg-transparent"/>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <img src={process.env.PUBLIC_URL+'/images/modal/icon-reward.png'} alt="icon-reward.png" className="w-[25%] m-auto mt-20"/>

                            <div className="text-black max-w-[316px] m-auto mt-5 mb-5 overflow-auto max-h-[182px]">
                                <table className="border-collapse border border-slate-500 w-full">
                                    <thead>
                                        <tr>
                                            <th className="border border-gray-300 font-display py-[10px]">Target</th>
                                            <th className="border border-gray-300 font-display">Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 1</td>
                                            <td className="border border-gray-300 text-center font-display">8</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 2</td>
                                            <td className="border border-gray-300 text-center font-display">20</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 3</td>
                                            <td className="border border-gray-300 text-center font-display">6</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 4</td>
                                            <td className="border border-gray-300 text-center font-display">4</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 5</td>
                                            <td className="border border-gray-300 text-center font-display">10</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 6</td>
                                            <td className="border border-gray-300 text-center font-display">8</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 7</td>
                                            <td className="border border-gray-300 text-center font-display">6</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 8</td>
                                            <td className="border border-gray-300 text-center font-display">30</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-300 text-center font-display py-[10px]">Hole 9</td>
                                            <td className="border border-gray-300 text-center font-display">4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div> </>) : '' }
        </>
    );
}

export default Rank;