import { useEffect, useRef, useState } from "react";

import io from 'socket.io-client'
import * as mediasoupClient from 'mediasoup-client';

const Video = (props) => {
    const videoRef = useRef(null);
    const [socketConfig, setSocketConfig] = useState(null);
    const screenVideo = useRef();

    const [lebar, setLebar] = useState(381);
    const [tinggi, setTinggi] = useState(504);

    const [kiri, setKiri] = useState('');
    const [atas, setAtas] = useState('');

    useEffect(() => {
        const socket = io(process.env.REACT_APP_HOSTSTREAM, {
            path: '/ws',
            transports: ['websocket'],
            query: {
                roomId: props.cam,
            }
        });

        setSocketConfig(socket);
    }, [props.cam]);

    useEffect(() => {
        if(socketConfig !== null){
            // A device is an endpoint connecting to a Router on the
            // server side to send/receive media
            const createDevice = () => {
                // make a request to the server for Router RTP Capabilities
                // see server's socket.on('getRtpCapabilities', ...)
                // the server sends back data object which contains rtpCapabilities
                socketConfig.emit('getRtpCapabilities', (data) => {
                    // console.log(`Router RTP Capabilities... ${data.rtpCapabilities}`)
        
                    try {
                        let device = new mediasoupClient.Device()
        
                        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
                        // Loads the device with RTP capabilities of the Router (server side)
                        device.load({
                                routerRtpCapabilities: data.rtpCapabilities
                            })
                            .then(() => {
                                createRecvTransport(device)
        
                                // console.log('RTP Capabilities', device.rtpCapabilities)
                            })
        
                    } catch (error) {
                        // console.log(error)
                        if (error.name === 'UnsupportedError')
                            console.warn('browser not supported')
                    }
                })
            }
        
            const createRecvTransport = (device) => {
                // see server's socket.on('consume', sender?, ...)
                // this is a call from Consumer, so sender = false
                socketConfig.emit('createWebRtcTransport', {
                    sender: false
                }, ({
                    params
                }) => {
                    // The server sends back params needed
                    // to create Send Transport on the client side
                    if (params.error) {
                        // console.log(params.error)
                        return
                    }
        
                    // console.log(params)
        
                    // creates a new WebRTC Transport to receive media
                    // based on server's consumer transport params
                    // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-createRecvTransport
                    let consumerTransport = device.createRecvTransport(params)
        
                    // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
                    // this event is raised when a first call to transport.produce() is made
                    // see connectRecvTransport() below
                    consumerTransport.on('connect', ({
                        dtlsParameters
                    }, callback, errback) => {
                        try {
                            // Signal local DTLS parameters to the server side transport
                            // see server's socket.on('transport-recv-connect', ...)
                            socketConfig.emit('transport-recv-connect', {
                                dtlsParameters,
                            })
        
                            // Tell the transport that parameters were transmitted.
                            callback()
                        } catch (error) {
                            // Tell the transport that something was wrong
                            errback(error)
                        }
                    })
        
                    // connectRecvTransport(device, consumerTransport, 'audio')
                    connectRecvTransport(device, consumerTransport, 'video')
                })
            }
        
            const connectRecvTransport = (device, consumerTransport, kind) => {
                // for consumer, we need to tell the server first
                // to create a consumer based on the rtpCapabilities and consume
                // if the router can consume, it will send back a set of params as below
                socketConfig.emit('consume', {
                    rtpCapabilities: device.rtpCapabilities,
                    kind,
                }, ({
                    params
                }) => {
                    if (params.error) {
                        console.log('Cannot Consume')
                        return
                    }
        
                    // console.log(params)
        
                    // then consume with the local consumer transport
                    // which creates a consumer
                    consumerTransport.consume({
                            id: params.id,
                            producerId: params.producerId,
                            kind: params.kind,
                            rtpParameters: params.rtpParameters
                        })
                        .then((consumer) => {
                            // destructure and retrieve the video track from the producer
                            const {
                                track
                            } = consumer
        
                            const stream = new MediaStream([track])
                            
                            switch (params.kind) {
                                case 'video':
                                    let video = videoRef.current;
                                    video.srcObject = stream;
                                    video.play();
                                    break;
                            }
        
                            // the server consumer started with media paused
                            // so we need to inform the server to resume
                            socketConfig.emit('consumer-resume', {
                                kind: params.kind
                            });
                            
                            props.consumecamconnected('200');
                        })
                })
            }

            socketConfig.on('connect', createDevice);
        }
    }, [socketConfig]);

    useEffect(() => {
		let videoRatio = lebar / tinggi;

        let screenWidth = window.innerWidth
        if (screenWidth > 500) screenWidth = 500;
        let screenHeight = window.innerHeight - 240;

        let frameRatio = screenWidth / screenHeight;

        let tinggiSet;
        let lebarSet;
        if(frameRatio > videoRatio){
            tinggiSet = screenHeight;
            lebarSet = tinggiSet * videoRatio;

            setTinggi(tinggiSet);
            setLebar(lebarSet);
        }else{
            lebarSet = screenWidth;
            tinggiSet = lebarSet / videoRatio;

            setLebar(lebarSet);
            setTinggi(tinggiSet);
        }

        let kiriSet = 0;
        if(lebarSet < screenWidth){
            kiriSet = (screenWidth - lebarSet) / 2;
            setKiri(kiriSet);
        }

        let atasSet = 0;
        if(tinggiSet < screenHeight){
            atasSet = (screenHeight - tinggiSet) / 2;
            setAtas(atasSet);
        }
    }, [lebar, tinggi, kiri]);
        
    return(
        <>
            <div className="w-full h-full absolute">
                <div className="absolute w-full top-[70px] sm:bottom-[170px] md:bottom-[200px]">
                    <div className="bg-black h-full w-full relative" ref={screenVideo}>
                        <div className="absolute" style={{ left : kiri, top : atas, width : lebar, height: tinggi}}>
                            <video ref={videoRef} controls={false} className="object-cover w-full h-full" style={{ rotate: props.roomconfig === -1 ? '0deg' : props.roomconfig+'deg' }}
                            autoPlay muted playsInline={true} disablePictureInPicture={true} controlsList="nodownload" 
                            ></video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Video;