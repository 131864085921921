import { Tooltip } from "@material-tailwind/react";

const Support = (props) => {
    return(
        <>
            {props.chat ? 
            <Tooltip content="Under Construction">
                <img src={process.env.PUBLIC_URL+'/new-skin/chat.png'} alt="chat.png" className="sm:w-[40px] md:w-arcadesWidth50px cursor-pointer active:translate-y-[4px]"/>
            </Tooltip>
            : '' }
            
            {props.camera ?
            <div className="ml-auto">
                <img src={process.env.PUBLIC_URL+'/new-skin/camera.png'} alt="camera.png" className="sm:w-[40px] md:w-arcadesWidth50px cursor-pointer active:translate-y-[4px]" onClick={props.changecam}/>
            </div> : '' }
        </>
    );
}

export default Support;