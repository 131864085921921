const Thank = (props) => {
    return(
        <>
            {props.modal ? (<>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t"></div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <img src={process.env.PUBLIC_URL+'/images/modal/thank-you.png'} alt="thank-you.png" className="cursor-pointer" onClick={props.exit}/>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 rounded-b"></div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div></>) : ''}
        </>
    );
}

export default Thank;