import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const ViewerSpeedTest = (props) => {
    return(
        <>
            <div className="absolute top-[104px] text-black right-0 z-10 bg-cover p-[10px] px-5" style={{ backgroundImage: "url('/new-skin/viewer-bg.png')"}}>
                <div className="text-center">
                    <FontAwesomeIcon icon={faEye} className="text-white"/>
                    <label className="pl-1 text-white font-bold">{props.viewer}</label>
                </div>
                {/* <label>0 ms</label> */}
            </div>
        </>
    );
}

export default ViewerSpeedTest;