const Countdown = (props) => {
    return(
        <>
            {props.modal ? ( <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div className="border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t"></div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <img src={process.env.PUBLIC_URL+'/images/modal/modal-stars.png'} alt="modal-stars.png" className="animate-arcadesStart"/>

                            {props.countdownnum !== '0' ? <>
                            <div className={`mt-[-165px] `+props.countdown}>
                                <img src={process.env.PUBLIC_URL+`/new-skin/`+props.countdownnum+`.png`} alt={+props.countdownnum+`.png`} className="w-[50%] m-auto"/>
                            </div></> : '' }

                            <div className={props.preball}>
                                <img src={process.env.PUBLIC_URL+'/images/modal/ribbon.png'} alt="ribbon.png" className="w-[75%] m-auto"/>
                                <label className="sm:text-[18px] md:text-[28px] text-[white] absolute top-[72%] left-[48%] translate-x-[-50%] translate-y-[-50%] font-display after:content-['.'] after:animate-arcadesDots">Preparing the Ball</label>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 rounded-b"></div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div> </> ) : '' }
        </>
    );
}

export default Countdown;