const Help = (props) => {
    return(
        <>
            {props.modal ? (<>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl relative flex flex-col w-full outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 rounded-t mt-[-60px] absolute ">
                            <img src={'/images/modal/how-to-play.png'} alt="how-to-play.png" className="w-[75%] m-auto bg-no-repeat bg-transparent"/>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto w-full mt-5">
                            <h3 className="text-center pt-12 text-black font-bold text-3xl font-display pb-5">Tania</h3>
                            <img src={process.env.PUBLIC_URL+'/images/modal/button-lapor.png'} alt="button-lapor.png" className="w-12 m-auto cursor-pointer animate-bounce" onClick={props.report}/>
                            <small className="text-black text-center block pt-5 w-[272px] m-auto">If there is any machine problem, please let us know by clicking the speaker button above</small>

                            <div className="max-w-[282px] m-auto mt-5 overflow-auto max-h-[224px]">
                                <ol className="text-black text-left list-decimal pl-[20px]">
                                    <li className="font-bold font-display marker:text-red-500">
                                        Click "Play" button to start the game<br/>
                                        <span className="pt-2 block">Klik tombol "Play" untuk memulai permainan</span>
                                    </li>
                                    <li className="font-bold font-display pt-[10px] marker:text-red-500">
                                        When ready, click "Go" button to kick the ball<br/>
                                        <span className="pt-2 block">Saat siap, klik tombol "Go" untuk menendang bola</span>
                                    </li>
                                    <li className="font-bold font-display pt-[10px] marker:text-red-500">
                                        You can choose to accumulate the points or exit the game<br/>
                                        <span className="pt-2 block">Anda dapat memilih untuk mengumpulkan poin atau keluar dari permainan</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div></>) : '' }
        </>
    );
}

export default Help;