const Report = (props) => {
    return(
        <>
            { props.modal ? (<>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl border-0 rounded-lg relative flex flex-col w-full outline-none focus:outline-none">
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <h3 className="text-center text-black font-bold font-display text-[20px] pt-[3.25rem]">Please Select your problem</h3>
                            <small className="block text-red-600 text-center font-display">{props.validation}</small>

                            <div className="mt-5">
                                <div className="flex mx-[64px]">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300">
                                        <input type="radio" name="option" className="cursor-pointer" value="q1" onChange={(e) => props.inputoption(e.target.value)}/>
                                    </span>
                                    <input type="text" className="outline-none rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 block flex-1 min-w-0 w-full text-sm p-2.5 font-display" disabled placeholder="Camera Blank"/>
                                </div>
                                <div className="flex mx-[64px] mt-3">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300">
                                        <input type="radio" name="option" className="cursor-pointer" value="q2" onChange={(e) => props.inputoption(e.target.value)}/>
                                    </span>
                                    <input type="text" className="outline-none rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 block flex-1 min-w-0 w-full text-sm p-2.5 font-display" disabled placeholder="Button Not Working"/>
                                </div>
                                <div className="flex mx-[64px] mt-3">
                                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300">
                                        <input type="radio" name="option" className="cursor-pointer" value="q3" onChange={(e) => props.inputoption(e.target.value)}/>
                                    </span>
                                    <input type="text" className="outline-none rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 block flex-1 min-w-0 w-full text-sm p-2.5 font-display" disabled placeholder="Play Button Not Working"/>
                                </div>
                                <div className="mx-[64px] mt-3">
                                    <input type="text" className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 font-display" placeholder="Other Options?" onChange={(e) => props.inputother(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            <button className="bg-arcadesGradients font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] mr-2.5 active:translate-y-[4px]" onClick={props.report}>
                                Report
                            </button>
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div> </>) : '' }
        </>
    );
}

export default Report;