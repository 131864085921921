import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const LeaderBoard = () => {
    return(
        <>
            <div className="border-b-[1px] border-white h-[25px]">
                <div className="float-left">
                    <FontAwesomeIcon icon={faCircle} className="pr-1 text-[#245e9e]"/>
                    <span className="text-[14px]">cloud</span>
                </div>
                <div className="float-right pr-1">33</div>
            </div>
            <div className="border-b-[1px] border-white h-[25px]">
                <div className="float-left">
                    <FontAwesomeIcon icon={faCircle} className="pr-1 text-[#245e9e]"/>
                    <span className="text-[14px]">cloud</span>
                </div>
                <div className="float-right pr-1">33</div>
            </div>
            <div className="border-b-[1px] border-white h-[25px]">
                <div className="float-left">
                    <FontAwesomeIcon icon={faCircle} className="pr-1 text-[#245e9e]"/>
                    <span className="text-[14px]">cloud</span>
                </div>
                <div className="float-right pr-1">33</div>
            </div>
            <div className="border-b-[1px] border-white h-[25px]">
                <div className="float-left">
                    <FontAwesomeIcon icon={faCircle} className="pr-1 text-[#245e9e]"/>
                    <span className="text-[14px]">cloud</span>
                </div>
                <div className="float-right pr-1">33</div>
            </div>
            <div className="border-b-[1px] border-white h-[25px]">
                <div className="float-left">
                    <FontAwesomeIcon icon={faCircle} className="pr-1 text-[#245e9e]"/>
                    <span className="text-[14px]">cloud</span>
                </div>
                <div className="float-right pr-1">33</div>
            </div>
        </>
    );
}

export default LeaderBoard;