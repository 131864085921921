// import { useEffect } from "react";
import Router from "./routes/Router";

const App = () => {
	// useEffect(() => {
	// 	navigator.geolocation.getCurrentPosition(function(position) {
	// 		console.log("Latitude is :", position.coords.latitude);
	// 		console.log("Longitude is :", position.coords.longitude);
	// 		console.log(position);
	// 	});
	// }, []);

	return(
		<>
			<Router/>
		</>
	);
}

export default App;