const RoomTimer = (props) => {
    return(
        <> 
            {props.roomtimer ? 
            <div className="absolute top-[104px] left-[13px] bg-arcadesBgColor w-[50px] h-[50px] text-center leading-[50px] rounded-[50%] z-10">{props.numroomtimer}</div>
            : '' }
        </>
    );
}

export default RoomTimer;