import { useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import CryptoJS from 'crypto-js';

import io from 'socket.io-client';
import axios from "axios";

import Header from "../../../components/header/Header";
import Video from "../../../components/streaming/Video";
import PlayButton from "../../../components/controls/PlayButton";
import GoButton from "../../../components/controls/GoButton";
import Support from "../../../components/support/Support";
import Countdown from "../../../components/modal/Countdown";
import WinScore from "../../../components/modal/WinScore";
import RoomTimer from "../../../components/roomtimer/RoomTimer";
import Audio from "../../../components/audios/Audio";
import ViewerSpeedTest from "../../../components/support/ViewerSpeedTest";
import Help from "../../../components/modal/Help";
import Report from "../../../components/modal/Report";
import Thank from "../../../components/modal/Thank";
import Rank from "../../../components/modal/Rank";
import LessBalance from "../../../components/modal/LessBalance";

const Selena = () => {
	const { credentials } = useParams();
	const [uuid, setUuid] = useState({});

	const [configSocket, setConfigSocket] = useState(null);
	const [modalCountdown, setModalCountdown] = useState(false);
	const [preBall, setPreBall] = useState('');
	const [countDown, setCountDown] = useState('');
	const [countDownNum, setCountDownNum] = useState('');

	const [modalWinScore, setModalWinScore] = useState(false);
	const [reward, setReward] = useState('');
	const [continueCountDown, setContinueCountDown] = useState(0);

	const [roomTimer, setRoomTimer] = useState(false);
	const [numRoomTimer, setNumRoomTimer] = useState('0');

	const [viewer, setViewer] = useState('0');

	const [audioRoom, setAudioRoom] = useState(true);
	const [audioName, setAudioName] = useState('');

	const [help,setHelp] = useState(false);
	const [option, setOption] = useState(null);
	const [inputOther, setInputOther] = useState(null);

	const [report, setReport] = useState(false);
	const [reportValidation, setReportValidation] = useState('');

	const [thank, setThank] = useState(false);
	const [rank, setRank] = useState(false);

	const [camera, setCamera] = useState('');
	const [lessBalance, setLessBalance] = useState(false);
	const [roomconfig, setRoomConfig] = useState({});
	const [plays, setPlays] = useState({});
	const [arrCamera, setArrCamera] = useState([]);
	const [camConnected, setCamConnected] = useState(false);

	useEffect(() => {
		try {
			let base64decode = atob(credentials);
			let decrypted = CryptoJS.AES.decrypt(base64decode, process.env.REACT_APP_SECRETENCRYPT).toString(CryptoJS.enc.Utf8);
			let uuid = JSON.parse(decrypted);

			setUuid(uuid);
		} catch (err) {
			let uuid = {
				'user' : '',
				'session' : ''
			}

			setUuid(uuid);
		}
	}, [credentials]);

	const objectSocket = useMemo(() => [
		{
			"ACT": "",
			"CTRL": "",
			"CD_PLAY": "",
			"CD_CONT": "",
			"CD_RDY": "",
			"STS_MACH": "",
			"RWD": "",
			"AGENT": uuid.contractcode,
			"STS_USR": uuid.user,
			"TOKEN": uuid.session,
			"MAX_CONT": "",
			"CURR_CONT": "",
			"RM_MBR_CNT": ""
		}
	], [uuid]);

	const roomInfo = useCallback(() => {
		if(Object.keys(uuid).length > 0){
			axios({
				method : 'POST',
				url : process.env.REACT_APP_HOSTBEACON+'/roomconfig/v01/info',
				data : {
					username : uuid.user ? uuid.user : '',
					contractcode : uuid.contractcode,
					CONTRACT_SECRET_KEY : uuid.secrectcontract,
					session_token : uuid.session,
					roomcode : uuid.roomcode
				}
			}).then(res => {
				if(res.data.status){
					setRoomConfig(res.data.result.tableroomconfig);
					setPlays(res.data.result.tableuser);
					setArrCamera(res.data.result.tablevideostream);
				}
			})
		}
	}, [uuid]);

	useEffect(() => {
		roomInfo();
	}, [roomInfo]);

	useEffect(() => {
		if(arrCamera.length > 0){
			setCamera(arrCamera[0]);
		}
	}, [arrCamera]);

	const [socketInfo, setSocketInfo] = useState(objectSocket);

	useEffect(() => {
		const socketgame = io(process.env.REACT_APP_HOSTBEACON+'/selena01', {
			withCredentials: true,
			transports: ['websocket']
		});

		setConfigSocket(socketgame);
	}, []);

	const playHandle = async (params) => {
		if(uuid.user !== '' && uuid.session !== ''){
			let URL = process.env.REACT_APP_HOSTBEACON+'/selena01/'+params+'/'+ uuid.contractcode +'/'+uuid.user+'/'+uuid.session;
			
			let response = await axios.get(URL, {
				headers : {'Content-Type': 'application/json', 'Authorization': 'Basic '+window.btoa('apiusername:apiuserpassword')}
			});

			if(response.data.success === false){
				if(response.data.status_code !== '015'){
					window.location.href = roomconfig.tableroomconfigurlplayinvalid
				}else{
					setLessBalance(true);
				}
			}else{
				roomInfo()
				localStorage.setItem('isplaying', true);
				setAudioName('audio-insert-coin');
				setModalWinScore(false);
			}
		}else{
			window.location.href = roomconfig.tableroomconfigurlplayinvalid
		}
	}

	useEffect(() => {
		if(configSocket !== null){
			configSocket.on('socketioinfo', (data) => {
				setSocketInfo(data);
				setViewer(data.RM_MBR_CNT);
	
				if(localStorage.getItem('isplaying') === 'true' && data.STS_MACH !== 'AVL'){
					if(data.STS_MACH === 'PRP_BL'){
						setModalCountdown(true);
						setPreBall('block');
						setCountDown('hidden');
					}else if(data.STS_MACH === 'CD_RDY'){
						setPreBall('hidden');
						setCountDown('block');
						setCountDownNum(data.CD_RDY);
		
						if(data.CD_RDY === '3'){
							setAudioName('audio-countdown');
						}
						if(data.CD_RDY === '0'){
							setTimeout(() => {
								setAudioName('');
							}, 1000);
						}
					}else{
						setModalCountdown(false);
					}
		
					if(data.STS_MACH === 'CD_PLAY'){
						setNumRoomTimer(data.CD_PLAY);
						setRoomTimer(true);
		
						if(data.CD_PLAY === '3'){
							setAudioName('audio-countdown');
						}
					}else{
						setRoomTimer(false);
						setNumRoomTimer('0');
					}
		
					if(data.STS_MACH === 'RWD'){
						if(data.RWD !== ''){
							roomInfo();
							setAudioName('audio-win');
							setReward(data.RWD);
							setModalWinScore(true);
						}
					}
		
					if(data.STS_MACH === 'CD_CONT'){
						setContinueCountDown(data.CD_CONT);
						if(data.CD_CONT <= 0){
							setModalWinScore(false);
						}
					}
				}

				if(data.STS_MACH === 'AVL'){
					localStorage.setItem('isplaying', false);
				}
	
				if(process.env.REACT_APP_LOGMODE){
					console.log(data);
				}
			});
		}
	}, [configSocket, roomInfo]);

	const goHandle = useCallback(() => {
		if(configSocket !== null){
			setAudioName('audio-click');

			objectSocket[0].ACT = 'USR_CTRL';
			objectSocket[0].CTRL = 'K';
			configSocket.emit('socketiocontrol', objectSocket[0]);
		}
	}, [objectSocket, configSocket]);

	const exitHandle = () => {
		if(configSocket !== null){
			objectSocket[0].ACT = 'DONE';
			configSocket.emit('socketiocontrol', objectSocket[0]);
			setModalWinScore(false);
		}
	}

	const playAgainHandle = () => {
		playHandle('hit_cont');
	}

	const handleKeyPress = useCallback((event) => {
		if(event.key === 'Enter'){
			goHandle();
		}
	}, [goHandle]);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);
	}, [handleKeyPress]);

	const roomAudioHandle = () => {
		if(audioRoom === false){
			setAudioRoom(true);
		}else{
			setAudioRoom(false);
		}
	}

	const helpHandle = () => {
		if(help === false){
			setHelp(true);
		}else{
			setHelp(false);
		}
	}

	const inputOptionHandle = (e) => {
		setOption(e);
	}

	const inputOtherHandle = (e) => {
		setInputOther(e);
	}

	const reportHandle = () => {
		let messageReport = '';

		if(option === null){
			if(inputOther === null){
				setReportValidation('Choose An Option!');

				setTimeout(() => {
					setReportValidation('');
				}, 3000);

				return false;
			}else{
				messageReport = inputOther;
			}
		}else{
			if(option === 'q1'){
				messageReport = 'Camera Blank';
			}else if(option === 'q2'){
				messageReport = 'Button Not Working';
			}else if(option === 'q3'){
				messageReport = 'Play Button Not Working';
			}else{
				setReportValidation('There is an error!');
				return false;
			}
		}

		axios({
			method : 'POST',
			url : process.env.REACT_APP_HOSTBEACON+'/selena01/assistance',
			data : {
				contractcode : uuid.contractcode,
				CONTRACT_SECRET_KEY : uuid.secrectcontract,
				message : messageReport
			}
		}).then(res => {
			if(res.data.status){
				setReport(false);
				setThank(true);
			}
		});
	}	

	const handleReport = () => {
		if(report === false){
			setOption(null);
			setInputOther(null);
			setHelp(false);
			setReport(true);
		}else{
			setReport(false);
		}
	}

	const rankHandle = () => {
		if(rank === false){
			setRank(true)
		}else{
			setRank(false);
		}
	}

	const consumeCamHandle = (value) => {
		if(value === '200'){
			setCamConnected(true);
		}
	}

	const backSound = () => {
		if(process.env.REACT_APP_BACKSOUND === true){
			if(audioRoom){
				return(
					<audio loop={true} autoPlay={true}>
						<source src={process.env.PUBLIC_URL+'/audio/ofel.mp3'} type="audio/mp3"/>
						Your browser does not support the audio element.
					</audio>
				);
			}
		}
	}

    return(
        <>
			{backSound()}

			<Audio audioname={audioName}/>

            <div className="bg-slate-300 max-w-arcadesWidthMax m-auto h-full relative sm:overflow-x-hidden sm:overflow-y-hidden">
				<div className="sm:h-arcadesHeightCalc2 md:h-arcadesHeightCalc1">
					<Header urlImage={"url('/new-skin/header.png')"} roomaudio={roomAudioHandle} audioroom={audioRoom} play={plays} roomconfig={roomconfig}/>

					<RoomTimer roomtimer={roomTimer} numroomtimer={numRoomTimer}/>
					<ViewerSpeedTest viewer={viewer}/>

					<Video cam={camera} consumecamconnected={consumeCamHandle} camconnected={camConnected}/>

					{/* Modal */}
					<Countdown modal={modalCountdown} preball={preBall} countdown={countDown} countdownnum={countDownNum}/>
					<WinScore modal={modalWinScore} reward={reward} continue={continueCountDown} exit={exitHandle} playagain={playAgainHandle} urlwhatsapp={process.env.REACT_APP_LINKSHARE} title={`Yeay! You Got The Prize `+reward+` coin`}/>
					<Help modal={help} exit={() => setHelp(false)} report={handleReport}/>
					<Report modal={report} inputoption={inputOptionHandle} inputother={inputOtherHandle} report={reportHandle} exit={() => setReport(false)} validation={reportValidation}/>
					<Thank modal={thank} exit={() => setThank(false)}/>
					<Rank modal={rank} exit={() => setRank(false)}/>
					<LessBalance modal={lessBalance} payload={roomconfig} play={plays} exit={() => setLessBalance(false)}/>
				</div>
				<div style={{ backgroundImage: "url('/new-skin/footer.png')" }} className="bg-no-repeat bg-arcadesControl h-arcadesHeight147px absolute w-full">
					<div className="mx-4 pt-2 sm:mt-[126px] md:mt-[130px]">
						<div className="flex">
							<Support camera={false} chat={true}/>
						</div>
						<PlayButton play={() => playHandle('hit_strt')} socketInfo={socketInfo} help={helpHandle} rank={rankHandle} isplaying={localStorage.getItem('isplaying')} cointoplay={roomconfig.tableroomconfigconfigurationpointtoplay}/>
						<GoButton go={goHandle} socketInfo={socketInfo} isplaying={localStorage.getItem('isplaying')}/>
					</div>
				</div>
			</div>
        </>
    );
}

export default Selena;