import { useEffect, useState } from "react";

const ControlButton = (props) => {
    const [controlButton, setControlButton] = useState('');
    const [disabledButton, setDisabledButton] = useState('');

    useEffect(() => {
        if(props.isplaying === 'true' && props.socketInfo.STS_MACH !== 'AVL'){
            if(props.socketInfo.STS_MACH === 'CD_PLAY'){
                setControlButton('block')
                setDisabledButton('');
            }
            else if(props.socketInfo.STS_MACH == 'GRBING' || props.socketInfo.STS_MACH == 'RWDING' || (props.socketInfo.STS_MACH == 'CD_CONT' && props.socketInfo.CD_CONT != 0)){
                setControlButton('block')
                setDisabledButton('disabled')
            }
            else{
                setControlButton('hidden')
            }
        }else{
            setControlButton('hidden');
        }
        
    }, [props]);

    return(
        <>  
            <div className={controlButton}>
                <div className="flex items-center sm:mt-[-27px] md:mt-[-46px] justify-center">
                    <div className="sm:mr-2 md:mr-10 relative">
                        <div className="text-center sm:mb-[-12px] md:mb-[-3px]">
                            <button style={{ backgroundImage: "url('/new-skin/ctrl-up.png')"}} className="sm:w-[60px] md:w-[75px] h-11 bg-arcadesControl active:translate-y-[4px]" disabled={disabledButton}
                            onMouseDown={props.up} onTouchStart={props.up} onTouchEnd={props.stop} onMouseUp={props.stop}/>
                        </div>
                        <div>
                            <button style={{ backgroundImage: "url('/new-skin/ctrl-left.png')"}} className="w-12 sm:h-[60px] md:h-[70px] bg-arcadesControl sm:mr-[60px] md:mr-[80px] active:translate-y-[4px]" disabled={disabledButton}
                            onMouseDown={props.left} onTouchStart={props.left} onTouchEnd={props.stop} onMouseUp={props.stop}/>
                            <button style={{ backgroundImage: "url('/new-skin/ctrl-right.png')"}} className="w-12 sm:h-[60px] md:h-[70px] bg-arcadesControl active:translate-y-[4px]" disabled={disabledButton}
                            onMouseDown={props.right} onTouchStart={props.right} onTouchEnd={props.stop} onMouseUp={props.stop}/>
                        </div>
                        <div className="text-center sm:mt-[-12px] md:mt-[-3px]">
                            <button style={{ backgroundImage: "url('/new-skin/ctrl-down.png')"}} className="sm:w-[60px] md:w-[75px] h-11 bg-arcadesControl active:translate-y-[4px]" disabled={disabledButton}
                            onMouseDown={props.down} onTouchStart={props.down} onTouchEnd={props.stop} onMouseUp={props.stop}/>
                        </div>
                    </div>
                    <div>
                        <button style={{ backgroundImage: "url('/new-skin/capit.png')"}} className="sm:w-20 sm:h-20 md:w-32 md:h-32 bg-arcadesControl active:translate-y-[4px]" onClick={props.go} disabled={disabledButton}></button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ControlButton;