import { useEffect, useState } from "react";

const GoButton = (props) => {
    const [goButton, setGoButton] = useState('');
    const [disabledButton, setDisabledButton] = useState('');
    const [effectButtonClick, setEffectButtonClick] = useState('');

    useEffect(() => {
        if(props.isplaying === 'true' && props.socketInfo.STS_MACH !== 'AVL'){
            if(props.socketInfo.STS_MACH === 'CD_PLAY'){
                setGoButton('block');
                setDisabledButton('');
                setEffectButtonClick('active:translate-y-[4px]');
            }
            else if(props.socketInfo.STS_MACH === 'BL_RLING' 
            || props.socketInfo.STS_MACH === 'RWD' 
            || props.socketInfo.STS_MACH === 'CD_BNS' 
            || props.socketInfo.STS_MACH === 'BNS' 
            || props.socketInfo.STS_MACH === 'SWPING'
            || (props.socketInfo.STS_MACH === 'CD_CONT' 
            && props.socketInfo.CD_CONT !== 0)){
                setGoButton('block');
                setDisabledButton('disabled');
                setEffectButtonClick('');
            }else{
                setGoButton('hidden');
            }
        }else{
            setGoButton('hidden');
        }
    }, [props]);

    return(
        <>
            <div className={goButton}>
                <div className="text-center mt-[-16px]">
                    <button style={{ backgroundImage: "url('/new-skin/go.png')"}} className={`bg-arcadesControl h-24 w-[100px] `+effectButtonClick} 
                    onClick={props.go} disabled={disabledButton}></button>
                </div>
            </div>
        </>
    );
}

export default GoButton;