const LessBalance = (props) => {
    const topupHandle = () => {
        window.location.href = process.env.REACT_APP_REDIRECT
    }
    return(
        <>
            {props.modal ? (<>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto max-w-[30rem] w-full">
                    {/*content*/}
                    <div style={{ backgroundImage: "url('/images/modal/modal-box.png')"}} className="bg-arcadesControl relative flex flex-col w-full outline-none focus:outline-none">
                        {/*body*/}
                        <div className="relative p-6 flex-auto mt-[43px]">
                            <h1 className="text-black font-bold text-[28px] w-[165px] m-auto font-display text-center">Saldo kamu tidak cukup!</h1>
                            <p className="text-black text-center pt-3">Gold coin kamu :</p>

                            <div className="flex justify-center pt-2">
                                <img src={process.env.PUBLIC_URL+'/images/support/coin.png'} alt="coin.png" className="w-[45px]"/>
                                <p className="text-[#FF7C3E] font-bold text-[1.5rem] pt-1 pl-2">{props.play.tableuserpoint}</p>
                            </div>

                            <p className="text-center text-black pt-2">Untuk memainkan game ini, <br/> Kamu butuh minimal <span className="text-[#FF7C3E] font-bold">{props.payload.tableroomconfigconfigurationpointtoplay} gold coin</span></p>

                            <div className="w-full text-center mt-4">
                                <button style={{ backgroundImage: "url('/images/modal/button-yellow.png')"}} className="bg-arcadesControl active:translate-y-[4px]" onClick={topupHandle}>
                                    <span className="py-[1rem] px-[4rem] block font-bold">Top-Up Saldo</span>
                                </button>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center p-6 rounded-b justify-center z-10">
                            <button className="bg-arcadesGradients1 font-display text-[21px] text-white rounded-[15px] px-[20px] h-[45px] active:translate-y-[4px]" onClick={props.exit}>
                                Exit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-[0.40] fixed inset-0 z-40 bg-black"></div></>) : ''}
        </>
    );
}

export default LessBalance;