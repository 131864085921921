import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import List from "./lists/List";

const RoomList = () => {
    return(
        <>
            <div className="bg-slate-300 max-w-arcadesWidthMax m-auto h-full">
                <div className="bg-white p-arcadesPadding1">
                    <div className="flex flex-row">
                        <div className="mt-[4px] mr-2">
                            <a href="#">
                                <FontAwesomeIcon icon={faChevronLeft} className="text-[#ffbb00] text-[2.5rem]"/>
                            </a>
                        </div>
                        <div className="flex-initial w-[40%] mr-3">
                            <div className="flex flex-row bg-white rounded-[15px] shadow-arcadesShadow text-black border border-slate-300">
                                <div className="relative w-[58px] h-[47px]">
                                    <img src={process.env.PUBLIC_URL+'/images/user/profile.jpg'} alt="profile.jpg" className="w-[60px] h-[60px] rounded-full absolute top-[-8px] left-[-4px]"/>
                                </div>
                                <div className="pl-1 self-center font-bold">Space Test</div>
                            </div>
                        </div>
                        <div className="flex-initial w-[60%] mr-2">
                            <div className="flex flex-row bg-white rounded-[15px] shadow-arcadesShadow text-black border border-slate-300 h-[48px]">
                                <div className="flex-auto self-center border-r-[1px] border-slate-300">
                                    <div className="flex flex-row h-full">
                                        <div>
                                            <img src={process.env.PUBLIC_URL+'/images/support/coin.png'} alt="coin.png" className="w-[34px] h-[34px] mx-[9px]"/>
                                        </div>
                                        <div>
                                            <p className="leading-[11px] pt-[3px] font-bold">coin</p>
                                            <span className="pt-[-1px] text-[#ffbb00]">0</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-auto self-center">
                                    <div className="flex flex-row h-full">
                                        <div>
                                            <img src={process.env.PUBLIC_URL+'/images/support/diamond.png'} alt="diamond.png" className="w-[34px] h-[34px] mx-[9px]"/>
                                        </div>
                                        <div>
                                            <p className="leading-[11px] pt-[3px] font-bold">diamond</p>
                                            <span className="pt-[-1px] text-[#ffbb00]">3000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <img src={process.env.PUBLIC_URL+'/images/banner/banner-claw.png'} alt="banner-claw.png"/>
                </div>

                <div className="bg-white w-full h-full p-[20px] overflow-auto max-h-[65%]">
                    <List/>
                </div>
            </div>
        </>
    );
}

export default RoomList;