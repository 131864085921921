import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import CryptoJS from 'crypto-js';

const Authentication = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let roomname = params.roomname;
        let user     = params.user;
        let session  = params.session;
        let contractcode = params.contractcode;
        let secretcontract = params.secretcontract;
        
        let getRoomIdentity = roomname.substring(0, 2);

        let roomId = '';
        switch(getRoomIdentity){
            case 'TN':
                roomId += 'tania';
            break;
            case 'SL':
                roomId += 'selena';
            break;
            case 'KT':
                roomId += 'katy';
            break;
            case 'CL':
                roomId += 'claudia'+roomname.slice(-2);
            break;
            case 'DR':
                roomId += 'dora01'+roomname.slice(-1);
            break;
        }

        let createJsonParams = {
            'roomcode' : roomname,
            'user' : user,
            'session' : session,
            'contractcode' : contractcode,
            'secrectcontract' : secretcontract
        }

        let stringJsonParams = JSON.stringify(createJsonParams);
        let encrypted = CryptoJS.AES.encrypt(stringJsonParams, process.env.REACT_APP_SECRETENCRYPT).toString();
        let base64encode = btoa(encrypted);

        if(getRoomIdentity === 'CL'){
            navigate('/play/cl/'+roomId+'/'+base64encode)
        }else if(getRoomIdentity === 'DR'){
            navigate('/play/dr/'+roomId+'/'+base64encode)
        }else{
            navigate('/play/'+roomId+'/'+base64encode)
        }
    }, [params]);

    return(
        <></>
    );
}

export default Authentication;