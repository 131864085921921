const Audio = (props) => {
    return(
        <>
            {props.audioname === 'audio-click' ? 
            <audio autoPlay={true}>
                <source src={process.env.PUBLIC_URL+'/audio/click-single.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            : ''}

            {props.audioname === 'audio-countdown' ? 
            <audio autoPlay={true}>
                <source src={process.env.PUBLIC_URL+'/audio/count-start-3-sec.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            : ''}

            {props.audioname === 'audio-win' ? 
            <audio autoPlay={true}>
                <source src={process.env.PUBLIC_URL+'/audio/win-bgm.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            : ''}

            {props.audioname === 'audio-lose' ? 
            <audio autoPlay={true}>
                <source src={process.env.PUBLIC_URL+'/audio/lose-bgm.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            : ''}

            {props.audioname === 'audio-insert-coin' ?
            <audio autoPlay={true}>
                <source src={process.env.PUBLIC_URL+'/audio/insert-coin.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            : ''}
        </>
    );
}

export default Audio;